import React from "react"

const defaultState = {
  userObject: null,
  updateUser: (userObject) => {},
}

const UserContext = React.createContext(defaultState)


class UserProvider extends React.Component {
  state = {
    userObject: null,
  }

  updateUser = (newObject) => {
    //localStorage.setItem("dark", JSON.stringify(dark))

    //    console.log('new', newObject)
      this.setState({ userObject: newObject })
      //  console.log('now', this.state.userObject)
  }


  componentDidMount() {
    
    /*
    Reading Local Storage

    const lsDark = JSON.parse(localStorage.getItem("dark"))
    if (lsDark) {
      this.setState({ dark: lsDark })
    } else if (supportsDarkMode()) {
      this.setState({ dark: true })
    }
    */

  }
  render() {
    const { children } = this.props
    const { userObject } = this.state
    return (
      <UserContext.Provider
        value={{
          userObject,
          updateUser: this.updateUser,
        }}
      >
        <div>
        {
         // <h1 style={{backgroundColor: 'red', position: 'absolute'}}>{userObject?.email ? userObject['email'].toString() : 'user null'}</h1>
        }
        {children}
        </div>
      </UserContext.Provider>
    )
  }
}
export default UserContext
export { UserProvider }