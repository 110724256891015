// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-camii-fer-97-jsx": () => import("./../../../src/pages/camii.fer97.jsx" /* webpackChunkName: "component---src-pages-camii-fer-97-jsx" */),
  "component---src-pages-challenges-jsx": () => import("./../../../src/pages/challenges.jsx" /* webpackChunkName: "component---src-pages-challenges-jsx" */),
  "component---src-pages-feed-jsx": () => import("./../../../src/pages/feed.jsx" /* webpackChunkName: "component---src-pages-feed-jsx" */),
  "component---src-pages-following-jsx": () => import("./../../../src/pages/following.jsx" /* webpackChunkName: "component---src-pages-following-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-isurus-gaming-jsx": () => import("./../../../src/pages/isurus-gaming.jsx" /* webpackChunkName: "component---src-pages-isurus-gaming-jsx" */),
  "component---src-pages-login-jsx": () => import("./../../../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-my-clips-jsx": () => import("./../../../src/pages/my-clips.jsx" /* webpackChunkName: "component---src-pages-my-clips-jsx" */),
  "component---src-pages-my-troops-jsx": () => import("./../../../src/pages/my-troops.jsx" /* webpackChunkName: "component---src-pages-my-troops-jsx" */),
  "component---src-pages-preguntas-frecuentes-index-jsx": () => import("./../../../src/pages/preguntas-frecuentes/index.jsx" /* webpackChunkName: "component---src-pages-preguntas-frecuentes-index-jsx" */),
  "component---src-pages-quiz-jsx": () => import("./../../../src/pages/quiz.jsx" /* webpackChunkName: "component---src-pages-quiz-jsx" */),
  "component---src-pages-settings-jsx": () => import("./../../../src/pages/settings.jsx" /* webpackChunkName: "component---src-pages-settings-jsx" */),
  "component---src-pages-signup-jsx": () => import("./../../../src/pages/signup.jsx" /* webpackChunkName: "component---src-pages-signup-jsx" */),
  "component---src-pages-sobre-troop-jsx": () => import("./../../../src/pages/sobre-troop.jsx" /* webpackChunkName: "component---src-pages-sobre-troop-jsx" */),
  "component---src-pages-tournaments-jsx": () => import("./../../../src/pages/tournaments.jsx" /* webpackChunkName: "component---src-pages-tournaments-jsx" */),
  "component---src-pages-troop-cup-jsx": () => import("./../../../src/pages/troop-cup.jsx" /* webpackChunkName: "component---src-pages-troop-cup-jsx" */),
  "component---src-pages-troop-cup-signup-jsx": () => import("./../../../src/pages/troop-cup-signup.jsx" /* webpackChunkName: "component---src-pages-troop-cup-signup-jsx" */),
  "component---src-pages-troop-pro-jsx": () => import("./../../../src/pages/troop-pro.jsx" /* webpackChunkName: "component---src-pages-troop-pro-jsx" */),
  "component---src-pages-upgrade-jsx": () => import("./../../../src/pages/upgrade.jsx" /* webpackChunkName: "component---src-pages-upgrade-jsx" */),
  "component---src-pages-use-app-jsx": () => import("./../../../src/pages/use-app.jsx" /* webpackChunkName: "component---src-pages-use-app-jsx" */),
  "component---src-pages-user-jsx": () => import("./../../../src/pages/user.jsx" /* webpackChunkName: "component---src-pages-user-jsx" */),
  "component---src-pages-wip-jsx": () => import("./../../../src/pages/wip.jsx" /* webpackChunkName: "component---src-pages-wip-jsx" */)
}

